import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaxDocsTypes, TaxDocumentsListItem } from '@ciphr/domains/tax-docs/models';

export const taxDocsFeatureActions = createActionGroup({
  source: 'Tax Docs Feature',
  events: {
    'Load Types': emptyProps(),
    'Load Docs': emptyProps(),
    'Set Active Tax Doc Type': props<{ taxDocType: TaxDocsTypes }>(),
    'Open Download Tax Docs Confirmation Dialog': props<{ id?: TaxDocumentsListItem['id'] }>(),
    'Download Doc': props<{ id: TaxDocumentsListItem['id'] }>(),
    'Download All Docs': emptyProps(),
    'Change Tax Docs List Page': props<{ pageIndex: number; pageSize: number }>(),
  },
});
